//获取数据源列表
const getSourceList = `/gateway/hc-data/datacenter/source/page`;
//获取数据库类型
const getSelectList = `/gateway//hc-data/datacenter/source/select`
//新增数据源接口
const addSourceUrl = `/gateway/hc-data/datacenter/source/submit`
//预览 获取表字段信息
const watchInfo = `/gateway/hc-data/datacenter/source/field/list`
const getSourceDetail = `/gateway/hc-data/datacenter/source/detail`
//删除数据源
const removeSource = `/gateway/hc-data/datacenter/source/remove`
// 暂停启动接口
const getTastStatus = `/gateway/hc-data/datacenter/source/job`;
//查看接口
const getSourceData = `/gateway/hc-data/datacenter/source/view`;
export {
  getSourceList,
  getSelectList,
  addSourceUrl,
  watchInfo,
  getSourceDetail,
  removeSource,
  getTastStatus,
  getSourceData
};

